// All Css Here
@import '~bootstrap/dist/css/bootstrap.min.css';
@import "./assets/css/plugins.css";
@import "./assets/scss/style";
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
//@import 'node_modules/react-modal-video/scss/modal-video.scss';

.btn-default:disabled {
  background: darkgrey;
  border-color: darkgrey;
}
.btn-default:disabled:hover {
  background: darkgrey;
  border-color: darkgrey;
  color: white;
}

.invalid {
  border: 1px solid #b40e0e;
  background-color: #fddddd;
}

.invalid:focus {
  border-color: #ff8800;
  background-color: #fbe8d2;
}
